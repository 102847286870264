import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import ThemeState from 'context/theme/ThemeState';
import UserState from 'context/user/UserState';
import TemplateState from 'context/template/TemplateState';
import DocumentState from 'context/document/DocumentState';
import AuditState from 'context/audit/AuditState';

import Login from 'components/pages/login';
import { Logout, NotFound404 } from 'components/common';

import './themes/default/pages/home.scss';
import './themes/default/common.scss';
import './themes/default/elements.scss';
import './themes/default/nav.scss';
import './themes/default/sections.scss';
import './themes/dark/pages/home.scss';
import './themes/dark/elements-dark.scss';
import './themes/dark/sections-dark.scss';
import './themes/dark/nav-dark.scss';
import './themes/blue/pages/home.scss';
import './themes/blue/elements-blue.scss';
import './themes/blue/sections-blue.scss';
import './themes/blue/nav-blue.scss';

// import Wrapper from 'components/layouts/Wrapper';
// import { Home } from 'components/pages/home';
// import { DocumentStatus } from 'components/pages/documentStatus';
// import { CreateTemplatePage } from 'components/pages/createTemplatePage';
// import { ViewTemplatePage } from 'components/pages/viewTemplatePage';
// import { CreateDocumentPage } from 'components/pages/createDocumentPage';
// import { TemplatesListPage } from 'components/pages/templatesListPage';
import { AllTemplates } from 'components/pages/allTemplates';
import { ViewTemplate } from 'components/pages/viewTemplate';
import { CreateDocument } from 'components/pages/createDocument';
import { CVsList } from 'components/pages/cvsList';

const App = () => {
  return (
    <Router>
      <Box className="App">
        <ThemeState>
          <UserState>
            <DocumentState>
              <TemplateState>
                <AuditState>
                  {/* <Wrapper> */}
                  <Routes>
                    <Route exact path="/" element={<AllTemplates />} />
                    <Route
                      exact
                      path="/templates/:templateId"
                      element={<ViewTemplate />}
                    />
                    <Route
                      exact
                      path="/createDocument"
                      element={<CreateDocument />}
                    />
                    <Route exact path="/my-cvs" element={<CVsList />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/logout" element={<Logout />} />
                    <Route path="*" element={<NotFound404 />} />
                  </Routes>
                </AuditState>
              </TemplateState>
            </DocumentState>
            {/* </Wrapper> */}
          </UserState>
        </ThemeState>
      </Box>
    </Router>
  );
};

export default App;

{
  /* 
    <Route exact path="/" element={<Home />} />
    <Route
      exact
      path="/audit/:documentName"
      element={<DocumentStatus />}
    />
    <Route
      exact
      path="/createTemplate"
      element={<CreateTemplatePage />}
    />
    <Route
      exact
      path="/templates"
      element={<TemplatesListPage />}
    />
    <Route
      exact
      path="/template/view/:templateName"
      element={<ViewTemplatePage />}
    />
    <Route
      exact
      path="/template/create"
      element={<CreateTemplatePage />}
    />
    <Route
      exact
      path="/template/edit/:templateName"
      element={<CreateTemplatePage />}
    />
    <Route
      exact
      path="/createDocument/:templateId"
      element={<CreateDocumentPage />}
    />
    <Route
      exact
      path="/editDocument/:documentId"
      element={<CreateDocumentPage />}
    />  
 */
}
