import { useContext, useEffect, useState } from 'react';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import DocumentContext from 'context/document/DocumentContext';
import { InputField } from 'components/common/InputField';
import CoreLayout from 'components/layouts/CoreLayout';
import { deepCopy } from 'helpers';
import { SearchIcon } from 'components/common/icons/SearchIcon';
import { DatePickerIcon } from 'components/common/icons/DatePickerIcon';

export const CVsList = () => {
  const { state: documentState, getAllDocuments } = useContext(DocumentContext);

  const [filter, setFilter] = useState({
    keyword: '',
    tag: '',
    date: '',
  });
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [documentsList, setDocumentsList] = useState([]);

  useEffect(() => {
    getAllDocuments();
  }, []);

  useEffect(() => {
    // check structure of received folders data and compare it with the existing one
    console.log('doc', documentState?.documents);
    setDocumentsList(documentState?.documents);
  }, [documentState?.documents]);

  useEffect(() => {
    setFilteredDocuments(documentsList);
    // ! TODO consider already active filter ...??
  }, [documentsList]);

  const applyKeywordFilter = (value, list) => {
    return list.filter((document) => {
      return document.documentName.toLowerCase().includes(value.toLowerCase());
    });
  };

  const applyTagFilter = (value, list) => {
    return list.filter((document) => {
      return document.position.toLowerCase().includes(value.toLowerCase());
    });
  };

  const applyDateFilter = (value, list) => {
    //
  };

  const resetFilter = (filterType) => {
    let docs = deepCopy(documentsList);

    if (filter?.keyword && filterType !== 'keyword') {
      docs = applyKeywordFilter(filter?.keyword, docs);
    }
    if (filter?.tag && filterType !== 'tag') {
      docs = applyKeywordFilter(filter?.tag, docs);
    }
    if (filter?.date && filterType !== 'date') {
      docs = applyKeywordFilter(filter?.date, docs);
    }

    setFilteredDocuments(docs);
  };

  const handleFolderFilterChange = (filterType, value) => {
    setFilter({ ...filter, [filterType]: value });
    if (!value) {
      resetFilter(filterType);
      return;
    }

    let updatedDocumentsList = deepCopy(filteredDocuments);

    switch (filterType) {
      case 'keyword':
        updatedDocumentsList = applyKeywordFilter(value, filteredDocuments);
        break;
      case 'tag':
        updatedDocumentsList = applyTagFilter(value, filteredDocuments);
        break;
      case 'date':
        updatedDocumentsList = applyDateFilter(value, filteredDocuments);
        break;
    }

    setFilteredDocuments(updatedDocumentsList);
    //     setFilteredFoldersData(filteredDocuments);
    //     if (
    //       filteredDocuments.findIndex((folder) => folder.id === openedFolder?.id) ===
    //       -1
    //     ) {
    //       // if the currently opened folder is not a part of the filtered folders, then set first folder as opened
    //       setOpenedFolder(filteredDocuments[0]);
    //     }
  };

  return (
    <CoreLayout pageTitle="My CVs">
      <Box
        sx={{ display: 'flex', flexDirection: 'row', mb: '40px', flexGrow: 1 }}
      >
        <Grid container sx={{ width: 'calc(100% - 220px)' }} spacing="20px">
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
                mb: '4px',
                color: '#494E57',
              }}
            >
              Search by keyboard:
            </Typography>
            <InputField
              name="foldersFilter"
              placeholder="Search"
              value={filter?.keyword}
              fullWidth={true}
              clearable
              onChange={(_, value) =>
                handleFolderFilterChange('keyword', value)
              }
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ height: '16px', width: '16px' }} />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
                mb: '4px',
                color: '#494E57',
              }}
            >
              Search by tags:
            </Typography>
            <InputField
              name="foldersFilter"
              placeholder="Search"
              value={filter?.tag}
              fullWidth={true}
              clearable
              onChange={(_, value) => handleFolderFilterChange('tag', value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ height: '16px', width: '16px' }} />
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Box sx={{ width: '200px', ml: '20px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '20px',
              mb: '4px',
              color: '#494E57',
            }}
          >
            Search by date:
          </Typography>
          <InputField
            name="foldersFilter"
            placeholder="Select date"
            value={filter?.date}
            fullWidth={true}
            clearable
            onChange={(_, value) => handleFolderFilterChange('date', value)}
            startAdornment={
              <InputAdornment position="start">
                <DatePickerIcon sx={{ height: '16px', width: '16px' }} />
              </InputAdornment>
            }
          />
        </Box>
      </Box>

      <Grid
        container
        spacing={'20px'}
        sx={{
          mt: 0,
          justifyContent: 'flex-start',
          backgroundColor: 'white',
          borderRadius: '8px',
          p: '12px',
          mb: '40px',
        }}
      >
        {filteredDocuments?.map((document, index) => (
          <Grid item key={index} sx={{}}>
            <Box
              sx={{
                height: '160px',
                width: '118px',
                backgroundImage: `url("/template${1}.png")`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                borderRadius: '4px',
                position: 'relative',
              }}
              className="imgCustom"
            >
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  height: '44px',
                  backgroundColor: '#E4F0B4',
                  zIndex: 3,
                  width: '100%',
                  borderBottomRadius: '8px',
                  padding: '4px 8px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#494E57',
                  }}
                >
                  {document?.documentName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: '#707682',
                  }}
                >
                  {document?.date}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </CoreLayout>
  );
};
