import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getFoldersData = async () => {
  const response = await axios.get(`${BASE_URL}/folders`);
  return response?.data || [];
};

export const getDocuments = async () => {
  const response = await axios.get(`${BASE_URL}/documents`);
  return response?.data || [];
};

export const getDocumentById = async (documentId) => {
  const response = await axios.get(
    `${BASE_URL}/documents/document/documentId=${documentId}`
  );
  return response?.data || {};
};

export const createNewDocument = async (data) => {
  const response = await axios.post(`${BASE_URL}/documents/document`, data);
  return response?.data || [];
};

export const createNewFolder = async (folderName) => {
  const response = await axios.post(`${BASE_URL}/folders/folder`, {
    folderName,
  });
  return response?.data || [];
};

export const updateDocumentById = async (documentId, documentData) => {
  const response = await axios.put(
    `${BASE_URL}/documents/document/documentId=${documentId}`,
    documentData
  );
  return response?.data || [];
};

export const updateFolderById = async (folderId, folderName) => {
  const response = await axios.put(
    `${BASE_URL}/folders/folder/folderId=${folderId}`,
    {
      folderName,
    }
  );
  return response?.data || [];
};

export const deleteDocumentById = async (documentId) => {
  const response = await axios.delete(
    `${BASE_URL}/documents/document/documentId=${documentId}`
  );
  return response?.data || [];
};

export const deleteFolderById = async (folderId) => {
  const response = await axios.delete(
    `${BASE_URL}/folders/folder/folderId=${folderId}`
  );
  return response?.data || [];
};
