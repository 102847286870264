import { useReducer } from 'react';
import {
  getDocumentById,
  deleteDocumentById,
  getFoldersData,
  deleteFolderById,
  createNewFolder,
  updateDocumentById,
  createNewDocument,
  updateFolderById,
  getDocuments,
} from '../../api/document';
import DocumentContext from './DocumentContext';
import documentReducer from './DocumentReducer';
import {
  GET_DOCUMENT,
  IS_LOADING,
  CREATE_DOCUMENT,
  GET_FOLDERS,
  CREATE_FOLDER,
  DELETE_FOLDER,
  DELETE_DOCUMENT,
  UPDATE_FOLDER,
  UPDATE_DOCUMENT,
  GET_ALL_DOCUMENTS,
} from './types';
import { deepCopy } from 'helpers';

export const initialState = {
  document: {},
  allDocuments: [],
  selectedFolder: null,
  folders: [],
  isLoading: false,
};

const DocumentState = (props) => {
  // state allows us to access anything in the state and dispatch allows dispatching objects to the reducer
  // populate the templateReducer with the initial state to instantiate it
  const [state, dispatch] = useReducer(documentReducer, initialState);

  const getDocumentData = (documentId) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    getDocumentById(documentId)
      .then((result) => {
        // dispatch action to the reducer and update the state accordingly
        dispatch({
          type: GET_DOCUMENT,
          payload: result,
        });
        return result;
      })
      .finally((result) => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
        return result;
      });
  };

  const getAllDocuments = () => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    getDocuments()
      .then((res) => {
        dispatch({
          type: GET_ALL_DOCUMENTS,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const createDocument = (documentData) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    createNewDocument(documentData)
      .then((res) => {
        dispatch({
          type: CREATE_DOCUMENT,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const updateDocument = (documentId, documentData) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    updateDocumentById(documentId, documentData)
      .then((res) => {
        dispatch({
          type: UPDATE_DOCUMENT,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const updateFolder = (folderId, folderName) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    updateFolderById(folderId, folderName)
      .then((res) => {
        const index = state?.folders?.findIndex(
          (folder) => folder.id === folderId
        );
        if (index !== -1) {
          const updatedFolders = deepCopy(state?.folders);
          updatedFolders[index] = res;
          dispatch({
            type: UPDATE_FOLDER,
            payload: updatedFolders,
          });
        }
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const createFolder = (folderName) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    createNewFolder(folderName)
      .then((res) => {
        dispatch({
          type: CREATE_FOLDER,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const deleteDocument = (documentId) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    deleteDocumentById(documentId)
      .then((res) => {
        dispatch({
          type: DELETE_DOCUMENT,
          payload: res,
        });
        getFolders();
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const getFolders = () => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    getFoldersData()
      .then((res) => {
        dispatch({
          type: GET_FOLDERS,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const deleteFolder = (folderId) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    deleteFolderById(folderId)
      .then((res) => {
        dispatch({
          type: DELETE_FOLDER,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  return (
    <DocumentContext.Provider
      value={{
        state: state || {},
        getDocumentData,
        deleteDocument,
        createDocument,
        updateDocument,
        getFolders,
        createFolder,
        deleteFolder,
        updateFolder,
        getAllDocuments,
      }}
    >
      {props.children}
    </DocumentContext.Provider>
  );
};

export default DocumentState;
