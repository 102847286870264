import React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

export const InputField = ({
  name = '',
  label,
  type = 'text',
  value,
  style = {},
  inputStyle = {},
  rows = null,
  minNumber = null,
  maxNumber = null,
  minRows = 1,
  maxRows = 1,
  allowDecimals = false,
  multiline = false,
  required = false,
  clearable = false,
  fullWidth = false,
  size = 'small',
  placeholder = '',
  disabled = false,
  onChange = () => {},
  endAdornment = null,
  ...rest
}) => {
  return (
    <TextField
      name={name}
      label={label}
      type={type}
      value={value}
      placeholder={placeholder}
      multiline={multiline}
      fullWidth={fullWidth}
      {...(rows && { rows: rows })}
      size={size}
      step={allowDecimals ? '0.01' : '1'}
      minRows={rows !== 1 ? minRows : undefined}
      maxRows={rows !== 1 ? maxRows : undefined}
      required={required}
      disabled={disabled}
      InputProps={{
        inputProps: { min: minNumber, max: maxNumber },
        endAdornment: (
          <>
            {clearable && (
              <IconButton
                sx={{ visibility: value ? 'visible' : 'hidden' }}
                onClick={() => onChange(name, '')}
              >
                <ClearIcon />
              </IconButton>
            )}
            {endAdornment}
          </>
        ),
      }}
      sx={{
        display: 'block',
        mb: 2,
        '.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderTop: '0px',
          borderRight: '0px',
          borderLeft: '0px',
          borderBottom: '1px solid #00000040 !important',
        },
        '.MuiOutlinedInput-notchedOutline legend': {
          backgroundColor: 'white',
          borderRadius: '20px',
          visibility: 'visible',
        },
        '.MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
          color: 'black',
        },
        '& .MuiOutlinedInput-input': {
          zIndex: 1,
          ...inputStyle,
        },
        ...style,
      }}
      onChange={(event) => {
        onChange(event.target.name, event.target.value);
      }}
      {...{ rest }}
    />
  );
};
