import React, { useContext, useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import ThemeContext from 'context/theme/ThemeContext';
import TemplateContext from 'context/template/TemplateContext';
import DocumentContext from 'context/document/DocumentContext';
import { Alert, LoadingSpinner } from 'components/common';
import Sidebar from './Sidebar';
import ToggableSidebar from './ToggableSidebar';

/* This is a component that needs to be used as a wrapper of pages that we want  
  to have NavBar on the page and container layout */

const CoreLayout = ({
  hideSidebar = false,
  pageTitle,
  subTitle,
  customPageWrapperLayout = false,
  wrapperStyle = null,
  children,
}) => {
  const { theme, closeAlert, withTheme } = useContext(ThemeContext);
  const { state: templateState } = useContext(TemplateContext);
  const { state: documentState } = useContext(DocumentContext);
  const { shouldShowAlert, alertMessage } = theme || {};
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    setShowSpinner(templateState?.isLoading || documentState?.isLoading);
  }, [templateState?.isLoading, documentState?.isLoading]);

  return (
    <Grid item>
      {shouldShowAlert && (
        <Alert
          message={alertMessage}
          isOpen={shouldShowAlert}
          onClose={closeAlert}
        />
      )}
      <Sidebar
        hideSidebar={hideSidebar}
        pageTitle={pageTitle}
        subTitle={subTitle}
      >
        {showSpinner ? (
          <LoadingSpinner />
        ) : (
          <Grid
            item
            sx={{ width: '100%', ...wrapperStyle }}
            className={
              !customPageWrapperLayout ? withTheme('page-wrapper') : ''
            }
          >
            <Box sx={{ '&.MuiContainer-root': { p: 0 } }}>
              <Grid
                container
                rowSpacing={1}
                direction="column"
                className="container"
                sx={{ mt: 0 }}
              >
                {children}
              </Grid>
            </Box>
          </Grid>
        )}
      </Sidebar>
    </Grid>
  );
};

export default CoreLayout;
