import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  Stack,
  TextField,
  IconButton,
} from '@mui/material';
import { CustomButton } from 'components/common';
import CoreLayout from 'components/layouts/CoreLayout';
import { CloseIcon } from 'components/common/icons/CloseIcon';

const sections = [
  { sectionName: 'Title', items: ['Name Surname', 'Occupation'] },
  { sectionName: 'Contact Info', items: ['Portfolio link', 'Email', 'Phone'] },
  {
    sectionName: 'Contact Info 2',
    items: ['Portfolio link', 'Email', 'Phone'],
  },
  {
    sectionName: 'Contact Info 3',
    items: ['Portfolio link', 'Email', 'Phone'],
  },
];

export const CreateDocument = () => {
  const navigate = useNavigate();
  return (
    <CoreLayout
      customPageWrapperLayout={true}
      pageTitle="CV Constructor"
      subTitle="CV Constructor / Select Template / Create CV"
    >
      <Grid container sx={{ p: 0, mt: '8px' }} columnSpacing="60px">
        <Grid item xs={7}>
          <Stack
            spacing="32px"
            sx={{ backgroundColor: 'white', borderRadius: '12px', p: '20px' }}
          >
            {sections?.map((section) => (
              <Box>
                <Stack spacing="8px">
                  <Typography
                    sx={{
                      fontWeight: '700',
                      lineHeight: '24px',
                      color: '#6B8507',
                      fontSize: '16px',
                    }}
                  >
                    {section?.sectionName}
                  </Typography>
                  {section?.items?.map((item) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <TextField
                        variant="outlined"
                        placeholder={item}
                        className="document-input"
                        sx={{ flexGrow: 1 }}
                      />
                      {/* {section?.sectionName !== 'Title' ? (
                        <IconButton
                          sx={{
                            width: '36px',
                            height: '36px',
                            backgroundColor: '#F0F2F5',
                            borderRadius: '8px',
                          }}
                          src="/close-icon.svg"
                        >
                          <CloseIcon sx={{}} />
                        </IconButton>
                      ) : null} */}
                    </Box>
                  ))}
                </Stack>
              </Box>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={5}>
          {/* <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              mb: '40px',
            }}
          >
            <Typography
              sx={{
                py: '6px',
                textSize: '20px',
                lineHeight: '24px',
                fontWeight: '700',
                color: '#494E57',
              }}
            >
              Summer Green Template
            </Typography>
            <CustomButton
              label="Full Screen"
              variant="text"
              imgSrc="/fullScreen.svg"
              sx={{ width: '150px' }}
            />
          </Box> 
          */}
          {/* <Typography
            sx={{
              mb: '8px',
              fontSize: '16px',
              lineHeight: '24px',
              color: '#707682',
              fontWeight: '700',
            }}
          >
            Similar Templates
          </Typography>
          <Grid
            container
            spacing={'12px'}
            sx={{
              mt: 0,
              justifyContent: 'flex-start',
              backgroundColor: 'white',
              borderRadius: '12px',
              p: '12px',
              mb: '40px',
              ml: 0,
            }}
          >
            {[1, 2, 3].map((index) => (
              <Grid item key={index} sx={{}}>
                <Box
                  sx={{
                    height: '150px',
                    width: '110px',
                    backgroundImage: `url("/template${index}.png")`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '4px',
                  }}
                  className="imgCustom"
                />
              </Grid>
            ))}
          </Grid> */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <CustomButton
              variant="text"
              label="Back to Templates"
              imgSrc="/backicon.svg"
              onClick={() => navigate('/selectTemplate')}
              style={{ marginTop: '6px', marginRight: '12px' }}
            />
            {/* <CustomButton label="Use this Template" /> */}
          </Box>
        </Grid>
      </Grid>
    </CoreLayout>
  );
};
