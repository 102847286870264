import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { CustomButton } from 'components/common';
import CoreLayout from 'components/layouts/CoreLayout';
import { useContext, useEffect, useState } from 'react';
import TemplateContext from 'context/template/TemplateContext';

export const ViewTemplate = () => {
  const { templateId } = useParams();
  const navigate = useNavigate();

  const [templateData, setTemplateData] = useState(null);
  const [isUnavailable, setIsUnavailable] = useState(false);
  const { state: templateState, getTemplateData } = useContext(TemplateContext);

  useEffect(() => {
    getTemplateData(templateId);
  }, []);

  useEffect(() => {
    setTemplateData(templateState?.template);
    if (templateState?.template?.templateName === 'unavailable') {
      setIsUnavailable(true);
    }
  }, [templateState?.template]);

  return (
    <CoreLayout
      customPageWrapperLayout={true}
      pageTitle="CV Constructor"
      subTitle="CV Constructor / Select Template / Create CV"
    >
      <Grid container sx={{ p: 0 }}>
        <Grid item xs={7}>
          <img
            src="/viewTemplate1.png"
            alt=""
            style={{
              maxWidth: '100%',
              marginLeft: '-25px',
              marginTop: '-5px',
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              mb: '40px',
            }}
          >
            <Typography
              sx={{
                py: '6px',
                textSize: '20px',
                lineHeight: '24px',
                fontWeight: '700',
                color: '#494E57',
              }}
            >
              {templateData?.templateName}
            </Typography>
            {/* <CustomButton
              label="Full Screen"
              variant="text"
              imgSrc="/fullscreen.svg"
              sx={{ width: '150px' }}
            /> */}
            {/* dummy */}
          </Box>
          <Typography
            sx={{
              mb: '8px',
              fontSize: '16px',
              lineHeight: '24px',
              color: '#707682',
              fontWeight: '700',
            }}
          >
            Similar Templates
          </Typography>
          <Grid
            container
            spacing={'12px'}
            sx={{
              mt: 0,
              justifyContent: 'flex-start',
              backgroundColor: 'white',
              borderRadius: '12px',
              p: '12px',
              mb: '40px',
            }}
          >
            {[1, 2, 3].map((index) => (
              <Grid item key={index} sx={{}}>
                <Box
                  sx={{
                    height: '150px',
                    width: '110px',
                    backgroundImage: `url("/template${index}.png")`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '4px',
                  }}
                  className="imgCustom"
                />
              </Grid>
            ))}
          </Grid>
          {isUnavailable && (
            <Typography sx={{ mb: '40px' }}>
              This Template is unavailable for your{' '}
              <Box
                sx={{ display: 'inline', fontWeight: '700', color: '#6B8507' }}
              >
                Pricing Plan
              </Box>
              . Upgrade or select another one
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: isUnavailable ? 'flex-start' : 'flex-end',
            }}
          >
            <CustomButton
              variant="text"
              label="Back to Templates"
              imgSrc="/backicon.svg"
              onClick={() => navigate('/selectTemplate')}
              style={{ marginRight: '12px' }}
            />
            {!isUnavailable ? (
              <CustomButton
                label="Use this Template"
                onClick={() => navigate('/createDocument')}
              />
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </CoreLayout>
  );
};
