import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { CustomButton } from 'components/common';
import { HtmlTooltip } from 'components/common/HtmlTooltip';
import CoreLayout from 'components/layouts/CoreLayout';
import TemplateContext from 'context/template/TemplateContext';

export const AllTemplates = () => {
  const navigate = useNavigate();
  const [showSelectBtnIndex, setShowSelectBtnIndex] = useState(null);

  const [templatesList, setTemplatesList] = useState([]);
  const { state: templateState, getAllTemplates } = useContext(TemplateContext);

  useEffect(() => {
    getAllTemplates();
  }, []);

  useEffect(() => {
    setTemplatesList(templateState?.templates);
  }, [templateState?.templates]);

  const isTemplateUnavailable = (template) => {
    return template?.templateName === 'unavailable';
  };

  return (
    <CoreLayout
      pageTitle="CV Constructor"
      subTitle="CV Constructor / Select Template"
      wrapperStyle={{ padding: '0px 60px' }}
    >
      <Grid
        container
        spacing={'40px'}
        sx={{ mt: '-40px', justifyContent: 'flex-start' }}
      >
        {templatesList?.map((template, index) => (
          <Grid item key={index} sx={{}}>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
              }}
            >
              <div
                style={{
                  height: 'inherit',
                  borderRadius: '8px',
                  width: 'max-content',
                }}
                className="imgCustom"
                onMouseOver={() => setShowSelectBtnIndex(index)}
                onMouseLeave={() => setShowSelectBtnIndex(null)}
              >
                <img
                  style={{
                    height: '100%',
                    minWidth: '200px',
                    maxWidth: '240px',
                    borderRadius: '8px',
                  }}
                  src={`/template${index + 1}.png`}
                />
                {showSelectBtnIndex === index ? (
                  <CustomButton
                    label="Select this Template"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      lineHeight: '20px',
                      fontSize: '14px',
                      width: 'calc(100% - 40px)',
                      transform: 'translate(-50%, -50%)',
                    }}
                    onClick={() => navigate(`/templates/${template?.id}`)}
                  />
                ) : null}
              </div>

              {isTemplateUnavailable(template?.templateName) ? (
                <HtmlTooltip
                  title={
                    <Typography sx={{ fontSize: '14px', lineHeight: '20px' }}>
                      This Template is unavailable for your Pricing Plan.
                      Upgrade or select another one
                    </Typography>
                  }
                >
                  <img
                    src="/locked-mark.svg"
                    style={{
                      top: '0',
                      right: '0',
                      position: 'absolute',
                      zIndex: '10',
                    }}
                  />
                </HtmlTooltip>
              ) : null}
            </Box>
          </Grid>
        ))}
      </Grid>
    </CoreLayout>
  );
};
