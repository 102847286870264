import { Button, Typography, styled } from '@mui/material';

const commonStyles = {
  height: '48px',
  borderRadius: '12px',
  padding: '14px 20px',
  boxShadow: 'none',
  width: '100%',
};

const FilledButton = styled(Button)(({ style }) => ({
  ...commonStyles,
  ...style,
  backgroundColor: '#D5EB81',
  color: '#292D33',
  width: 'max-content',
  '&:hover': {
    backgroundColor: '#C4DB67',
  },
}));

const TextButton = styled(Button)(({ style }) => ({
  ...commonStyles,
  ...style,
  color: '#292D33',
  textDecoration: 'underline',
  width: 'max-content',
  padding: '8px 12px',
  height: '36px',
}));

const OutlinedButton = styled(Button)(({ style }) => ({
  ...commonStyles,
  ...style,
  border: '1px solid #292D33',
  color: '#fff',
  width: 'max-content',
}));

export const CustomButton = ({
  label,
  imgSrc,
  variant = 'contained',
  style = {},
  onClick = () => {},
}) => {
  const content = (
    <>
      {imgSrc ? <img src={`${imgSrc}`} alt="button" /> : null}
      <Typography
        variant="div"
        sx={{
          lineHeight: '20px',
          ml: imgSrc ? (variant === 'text' ? '4px' : '8px') : '0px',
          fontSize: '14px',
          textTransform: 'none',
        }}
      >
        {label}
      </Typography>
    </>
  );

  switch (variant) {
    case 'text':
      return (
        <TextButton style={style} onClick={onClick}>
          {content}
        </TextButton>
      );
    case 'outlined':
      return (
        <OutlinedButton style={style} onClick={onClick}>
          {content}
        </OutlinedButton>
      );
    default:
      return (
        <FilledButton style={style} onClick={onClick}>
          {content}
        </FilledButton>
      );
  }
};
